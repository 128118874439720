.content[data-v-0040f5d8] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: calc(100% - 46px);
  background: #ffffff;
}
.content .content-item[data-v-0040f5d8] {
  width: 47%;
}
.content .content-item .head-search[data-v-0040f5d8] {
  padding: 12px;
  background: #ffffff;
}
.content .tree-transfer-middle[data-v-0040f5d8] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 6%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: #fff;
}
.content[data-v-0040f5d8] .el-table {
  height: calc(100vh - 320px) !important;
  max-height: calc(100vh - 320px) !important;
}